import React, { useEffect, useMemo, useState } from "react";
import LogoutIcon from "@mui/icons-material/Logout";
import bullionStats from "../assets/bullioStats.png";
import logo from "../assets/logo.png";
import flag1 from "../assets/flag1.png";
import flag2 from "../assets/flag2.png";
import flag3 from "../assets/flag3.png";
import CircleIcon from "@mui/icons-material/Circle";
import {
  Box,
  Divider,
  Fade,
  LinearProgress,
  Modal,
  Typography,
  useMediaQuery,
} from "@mui/material";
import Marquee from "react-fast-marquee";
import "../App.css";
import moment from "moment-timezone";
import io from "socket.io-client";
import Carousel from "react-material-ui-carousel";
import {
  allCommodities,
  allNewses,
  findBanners,
  getLiveValueTypeForDisplay,
  getRatio,
  getSocketUrl,
  getSpread,
} from "../sevice/home";
import { loginChecking, logoutTv } from "../sevice/login";
import Cookies from "js-cookie";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import LimitExceededModal from "./LimitExceededModal";
import SubscriptionExpiringSoon from "./SubscriptionExpiringSoon";
import SubscriptionExpired from "./SubscriptionExpired";
const { makeStyles } = require("@mui/styles");
let socket = Cookies.get("socketUrl")
  ? io(JSON.parse(Cookies.get("socketUrl")))
  : null;

const socket2 = io(process.env.REACT_APP_BACKEND_URL);
const adminId = process.env.REACT_APP_ADMIN_ID;

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    width: "100dvw",
    height: "100dvh",
    backgroundColor: "#1A1A1A",
    margin: "auto",
    display: "grid",
    justifyContent: "space-between",
    gridTemplateColumns: "66% 32%",
    // gridTemplateColumns: "3fr 1.5fr",
    gridTemplateRows: "1fr",
    gridTemplateAreas: `
        'left right'
      `,
    padding: "2.5rem 2.5rem 2rem 2.5rem",
    boxSizing: "border-box",
    columnGap: "2rem",
  },

  left: {
    gridArea: "left",
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "space-between",
    boxSizing: "border-box",
    flexDirection: "column",
  },
  headerimg: {
    height: "12%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  logoImg: {
    width: "30%",
    alignItems: "center",
    objectFit: "contain",
  },

  timeBox: {
    height: "10%",
    backgroundColor: "#262626",
    boxSizing: "border-box",
    borderRadius: "15px",
    padding: "15px",
  },
  timeInnerBox: {
    height: "100%",
    backgroundColor: "#1A1A1A",
    border: "1px solid #4C4C4C",
    borderRadius: "15px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  dateBox: {
    height: "6%",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  commoditieTable: {
    gridArea: "commoditieTable",
    height: "56%",
    display: "flex",
    boxSizing: "border-box",
    flexDirection: "column",
    borderRadius: "15px",
    backgroundColor: "#262626",
  },
  table: {
    gridArea: "table",
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "column",
    height: "100%",
    padding: "20px",
  },
  tabeHeader: {
    gridArea: "tabeHeader",
    backgroundColor: "#1A1A1A",
    flex: "1",
    display: "flex",
    justifyContent: "center",
    borderRadius: "10px",
    alignItems: "center",
    height: "13%",
    border: "solid 1px #4C4C4C",
  },
  fontgoldColor: {
    backgroundImage: `linear-gradient(90deg, #C09445 1.19%, #EED770 37.04%, #C59C4A 76.42%, #F4E076 98.13%)`,
    WebkitBackgroundClip: "text", // For Safari
    backgroundClip: "text",
    color: "transparent",
  },
  tableContent: {
    gridArea: "tableContent",
    display: "flex",
    height: "85%",
    flexDirection: "column",
    // justifyContent: "space-between",
    overflowX: "scroll",
    overflowX: "hidden",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  tableRowColumn: {
    flex: "1",
    display: "flex",
    alignItems: "center",
    color: "#FFFFFF",
    // justifyContent: "center",
    fontSize: "2vw",
  },
  tableRow1: {
    flexBasis: 0,
    maxHeight: "17%",
    flexGrow: 1,
    gridArea: "tableRow1",
    background: "#262626",
    display: "flex",
  },
  updates: {
    color: "#fff",
    display: "flex",
    width: "100%",
    height: "8%",
    color: "#FFFFFF",
  },
  updatesHeader: {
    background: "#262626",
    minWidth: "210px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "10px",
    borderRight: "1px solid #4C4C4C",
  },
  updatesContent: {
    display: "flex",
    alignItems: "center",
    background: "#262626",
    // maxWidthidth: "100%",
    // flex: 1,
    // width: "71%",

    borderRadius: "0px 10px 10px 0px",
  },

  stat: {
    height: "85%",
    width: "100%",
    gridArea: "stat",
    backgroundColor: "#262626",
    borderRadius: "10px",
    color: "#fff",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  bullionStatsImg: {
    width: "9vw",
    height: "auto",
  },
  right: {
    gridArea: "right",
    boxSizing: "border-box",
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    padding: "2.5rem 0rem 0rem 0rem",
  },
  banner: {
    height: "30%",
    boxSizing: "border-box",
    padding: "20px",
    backgroundColor: "#262626",
    borderRadius: "15px 15px 0 0",
  },
  bannerImg: {
    width: "100%",
    height: "100%",
    objectFit: "fill",
    borderRadius: "15px",
    overflow: "hidden",
  },

  spotRate: {
    height: "50%",
    backgroundColor: "#262626",
    padding: "0 20px 20px 20px",
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "column",
    color: "#16341B",
    gap: ".6rem",
    borderRadius: "0 0 15px 15px",
    color: "#FFFFFF",
  },
  spotRateBoxGoldRow2Col1: {
    gridArea: "spotRateBoxGoldRow2Col1",
    display: "flex",
    flexDirection: "column",
    gap: "0.3rem",
    alignItems: "center",
    flexBasis: 0,
    flexGrow: 1,
    flexShrink: 1,
  },
  spotRateBoxGoldRow2Col2: {
    gridArea: "spotRateBoxGoldRow2Col2",
    display: "flex",
    flexDirection: "column",
    gap: "0.3rem",
    alignItems: "center",
    flexBasis: 0,
    flexGrow: 1,
    flexShrink: 1,
  },
  spotRateBoxSilverRow2Col1: {
    gridArea: "spotRateBoxSilverRow2Col1",
    display: "flex",
    flexDirection: "column",
    gap: "0.3rem",
    alignItems: "center",
    flexBasis: 0,
    flexGrow: 1,
    flexShrink: 1,
  },
  spotRateBoxSilverRow2Col2: {
    gridArea: "spotRateBoxSilverRow2Col2",
    display: "flex",
    flexDirection: "column",
    gap: "0.3rem",
    alignItems: "center",
    flexBasis: 0,
    flexGrow: 1,
    flexShrink: 1,
  },

  blur: {
    filter: "blur(8px)",
  },
}));

const Home = () => {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const { tv } = useSelector((state) => ({ ...state }));
  const classes = useStyles();

  // setTimeout(function () {
  //   window.location.reload();
  // }, 10 * 60 * 1000);

  const [silver, setSilver] = useState({
    ask: 0,
    bid: 0,
    highPrice: 0,
    lowPrice: 0,
  });
  const [previousSilver, setPreviousSilver] = useState();
  const [gold, setGold] = useState({
    ask: 0,
    bid: 0,
    highPrice: 0,
    lowPrice: 0,
  });
  const [previousGold, setPreviousGold] = useState();
  const [banners, setBanners] = useState([]);
  const [news, setNews] = useState([]);
  const [commodities, setCommodities] = useState([]);
  const [isMarketOpen, setIsMarketOpen] = useState(true);
  const [remainingTime, setRemainingTime] = useState(null);
  const [openlimitExpierd, setOpenlimitExpierd] = useState(false);
  const [openSubscription, setOpenSubscription] = useState(false);
  const [openSubscriptionExpierd, setOpenSubscriptionExpierd] = useState(false);
  const [displayBidOrBuy, setDisplayBidOrBuy] = useState({
    bidOrBuy: "Bid",
    askOrSell: "Ask",
  });
  const [time, setTime] = useState(new Date());
  const [spread, setSpread] = useState({
    goldAskSpread: 0,
    goldBidSpread: 0,
    silverAskSpread: 0,
    silverBidSpread: 0,
    goldLowSpread: 0,
    goldHighSpread: 0,
    silverLowSpread: 0,
    silverHighSpread: 0,
  });

  const [ratio, setRatio] = useState({
    Buyers: "77%",
    chgValue: "+0.370%",
    commodity: "Gold",
  });

  useMemo(() => {
    if (socket) {
      socket.on("connect", () => {});
      socket.on("disconnect", () => {});
      socket.on("connect_error", (err) => {
        console.log(`connect_error due to ${err.message}`);
      });
      socket.on("gold-rate-change", (data) => {
        setIsMarketOpen(data.data.isMarketOpen);
        setPreviousGold(gold);
        setGold({
          ask: data.data.ask,
          bid: data.data.bid,
          highPrice: data.data.highPrice,
          lowPrice: data.data.lowPrice,
        });
      });
      socket.on("silver-rate-change", (data) => {
        setPreviousSilver(silver);
        setSilver({
          ask: data.data.ask,
          bid: data.data.bid,
          highPrice: data.data.highPrice,
          lowPrice: data.data.lowPrice,
        });
      });
    }
  });

  useMemo(() => {
    let timerId;
    if (!isMarketOpen) {
      timerId = setInterval(() => {
        let currentTime = new Date();
        let nextMonday = new Date();
        let dubaiTime = moment(Date.now()).tz("Asia/Dubai");
        nextMonday.setFullYear(
          Number(dubaiTime.format("YYYY")),
          Number(dubaiTime.format("MM")) - 1,
          Number(dubaiTime.format("D"))
        );
        currentTime.setFullYear(
          Number(dubaiTime.format("YYYY")),
          Number(dubaiTime.format("MM")) - 1,
          Number(dubaiTime.format("D"))
        );
        currentTime.setHours(
          dubaiTime.format("H"),
          dubaiTime.format("mm"),
          dubaiTime.format("ss")
        );

        if (dubaiTime.format("ddd") !== "Mon") {
          nextMonday.setDate(
            nextMonday.getDate() + ((1 + 7 - nextMonday.getDay()) % 7 || 7)
          );
        }
        nextMonday.setHours(1, 59, 0, 0);
        const elapsedTime = nextMonday - currentTime;
        if (elapsedTime <= 0) {
          clearInterval(timerId);
        } else {
          setRemainingTime(elapsedTime);
        }
      }, 1000);
    }
    return () => clearInterval(timerId); // Clear the timer when the component unmounts or when startTimer becomes false
  }, [isMarketOpen]);

  const formatRemainingTime = (time) => {
    const seconds = Math.floor((time / 1000) % 60);
    const minutes = Math.floor((time / 1000 / 60) % 60);
    const hours = Math.floor((time / 1000 / 60 / 60) % 24);
    const days = Math.floor(time / 1000 / 60 / 60 / 24);

    return `${days}d ${hours}h ${minutes}m ${seconds}s`;
  };

  // function commodityCalculation(
  //   commodityPrice,
  //   goldAskSpread,
  //   premuimPerOunce,
  //   unit,
  //   label,
  //   purity,
  //   additionalCharge
  // ) {
  //   const usdToAed = 3.674;
  //   let value =
  //     (((commodityPrice + goldAskSpread + premuimPerOunce) * usdToAed) /
  //       31.1035) *
  //       purity *
  //       unit *
  //       label +
  //     additionalCharge;

  //   if (value.toFixed(0).length < 5) {
  //     return value.toLocaleString("en-US", {
  //       minimumFractionDigits: 2,
  //       maximumFractionDigits: 2,
  //     });
  //   } else {
  //     return value.toLocaleString("en-US", {
  //       // minimumFractionDigits: 2,
  //       maximumFractionDigits: 0,
  //     });
  //   }
  // }
  const commodityCalculation = (
    livePrice,
    spread,
    premium,
    fxRate,
    purity,
    unit,
    weight,
    charge
  ) => {
    let value =
      (Number(livePrice) + Number(spread) + Number(premium)) *
        (fxRate / 31.1035) *
        Number(purity) *
        Number(weight) *
        Number(unit) +
      Number(charge);
    if (value.toFixed(0).length < 5) {
      return value.toLocaleString("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    } else {
      return value.toLocaleString("en-US", {
        // minimumFractionDigits: 2,
        maximumFractionDigits: 0,
      });
    }
  };

  useEffect(() => {
    handelFindBanners();
  }, []);

  useEffect(() => {
    handleFindRatio();
  }, []);

  useEffect(() => {
    hadlefetchNews();
  }, []);

  useEffect(() => {
    handleFindSpread();
  }, []);

  useEffect(() => {
    handleFinsCommodities();
  }, []);

  useEffect(() => {
    handleloginChecking();
  }, []);

  useEffect(() => {
    handleFindLiveValueTypeForDisplay();
  }, []);

  useEffect(() => {
    const timer = setInterval(() => {
      setTime(new Date());
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  useEffect(() => {
    socket2.on("connect", () => {});
    socket2.on("disconnect", () => {});
    socket2.emit("join_room", { roomId: adminId });
    socket2.on("commodityRerender", (data) => {
      handleFinsCommodities();
    });
    socket2.on("newsRerender", (data) => {
      hadlefetchNews();
    });
    socket2.on("spreadRerender", (data) => {
      handleFindSpread();
    });
    socket2.on("loginChecking", (data) => {
      handleloginChecking();
    });
    socket2.on("mediaRerender", (data) => {
      handelFindBanners();
    });
    socket2.on("adminBlockRerender", (data) => {
      handleLogout();
    });
    socket2.on("socketRerender", (data) => {
      Cookies.remove("socketUrl");
      window.location.reload();
    });
    socket2.on("bidAndAskTitleRerender", (data) => {
      handleFindLiveValueTypeForDisplay();
    });
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      triggerExpire();
    }, 60 * 60 * 1000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    handlecheckSubscriptionExpierd();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      window.location.reload();
    }, 10 * 60 * 1000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    handleFindSocketUrl();
  }, []);

  const handleFindSocketUrl = async () => {
    const res = await getSocketUrl();
    if (res) {
      socket = io(res?.socketURL);
      Cookies.set("socketUrl", JSON.stringify(res?.socketURL));
    }
  };

  const progress = Number(ratio?.Buyers?.match(/\d+/)[0]);

  const buyersColor = "#10A88A";
  const sellersColor = "#EF2555";

  const progressStyle = {
    backgroundColor: sellersColor,
  };

  const barStyle = {
    backgroundColor: buyersColor,
  };

  const formatDate = () => {
    return moment(new Date()).format("dddd - DD MMM YYYY");
  };

  const handleloginChecking = async () => {
    const deviceId = Cookies.get("deviceId")
      ? JSON.parse(Cookies.get("deviceId"))
      : null;
    const res = await loginChecking(deviceId);
    console.log(res);
    if (res.status === false && res?.totalLoginCount <= res?.liveCounts) {
      setOpenlimitExpierd(true);
    } else if (res.status === false) {
      Cookies.remove("tv");
      Cookies.remove("deviceId");
      Cookies.remove("socketUrl");
      Cookies.remove("socketUrl");
      window.location.reload();
      dispatch({
        type: "LOGOUT",
      });
      navigate("/login");
    }
  };

  const handleOpen = () => setOpenSubscription(true);
  const handleClose = () => setOpenSubscription(false);
  const handleOpenSubscriptionExpierd = () => setOpenSubscriptionExpierd(true);
  const handleCloseSubscriptionExpierd = () =>
    setOpenSubscriptionExpierd(false);

  const handelFindBanners = async () => {
    const res = await findBanners();
    if (Array.isArray(res)) {
      setBanners(res);
    }
  };

  const handleFinsCommodities = async () => {
    const res = await allCommodities();
    if (Array.isArray(res.commodities)) {
      setCommodities(res.commodities);
    }
  };

  const handleFindSpread = async () => {
    const res = await getSpread();
    if (res) {
      setSpread(res);
    }
  };

  const handleFindRatio = async () => {
    const res = await getRatio();
    if (res?.data) {
      setRatio(res.data);
    }
  };

  const hadlefetchNews = async () => {
    const res = await allNewses();
    if (Array.isArray(res)) {
      setNews(res);
    }
  };

  const handleFindLiveValueTypeForDisplay = async () => {
    const res = await getLiveValueTypeForDisplay();
    if (res) {
      setDisplayBidOrBuy(res);
    }
  };

  async function triggerExpire() {
    const givenDate = new Date(tv?.package.expire_date);
    const oneMonthAgo = new Date(givenDate);
    oneMonthAgo.setDate(givenDate.getDate() - 30);
    let a = oneMonthAgo.toISOString();
    let b = new Date().toISOString();

    if (b >= a) {
      const currentDate = new Date();
      const currentHour = currentDate.getHours();
      if (currentHour === 10) {
        handleOpen();
      }
    }
  }

  const handlecheckSubscriptionExpierd = () => {
    const givenDate = new Date(tv?.package.expire_date);
    let a = givenDate.toISOString();
    let b = new Date().toISOString();
    if (a < b) {
      handleOpenSubscriptionExpierd();
    }
  };
  const handleLogout = async () => {
    const deviceId = Cookies.get("deviceId")
      ? JSON.parse(Cookies.get("deviceId"))
      : null;
    const res = await logoutTv({
      adminId: adminId,
      deviceId: deviceId,
    });
    if (res) {
      Cookies.remove("tv");
      Cookies.remove("deviceId");
      Cookies.remove("socketUrl");
      window.location.reload();
      dispatch({
        type: "LOGOUT",
      });
      navigate("/login");
    }
  };

  return (
    <div
      style={{ backgroundColor: "#1A1A1A", height: "100vh" }}
      className={(openlimitExpierd || openSubscriptionExpierd) && classes.blur}
    >
      <Box className={classes.mainContainer}>
        {!isMarketOpen && (
          <Box
            sx={{
              position: "fixed",
              top: "0",
              left: "-125px",
              right: "0",
              bottom: "0",
              width: "895px",
              height: "531px",
              overflow: "hidden",
              zIndex: "99",
            }}
          >
            <div
              style={{
                position: "relative",
                width: "100%",
                height: "10%",
                transform: "rotate(-35deg)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "#8C003D",
                color: "#FFFFFF",
                fontSize: "2vw",
                fontWeight: "bold",
                padding: "20px",
                lineHeight: 1.5,
                alignItems: "center",
                flexDirection: "column",
                justifyContent: "space-evenly",
              }}
            >
              <Marquee delay="2" gradient={false}>
                {remainingTime && (
                  <p style={{ marginLeft: "90px" }}>
                    Market closed! Opens on {formatRemainingTime(remainingTime)}
                  </p>
                )}
              </Marquee>
            </div>
          </Box>
        )}

        <Box className={classes.left}>
          <Box className={classes.headerimg}>
            <img className={classes.logoImg} src={logo} alt="logo" />
          </Box>
          <Box className={classes.timeBox}>
            <Box className={classes.timeInnerBox}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  ml: "20px",
                  gap: "10px",
                  width: "26%",
                }}
              >
                <img src={flag1} alt="" srcset="" style={{ height: "35px" }} />
                <Box>
                  <Typography
                    sx={{
                      fontSize: { lg: "1.8rem", md: "1.6rem", sm: "1.5rem" },
                      fontWeight: "bold",
                      color: "#A9A9A9",
                      letterSpacing: "3px",
                    }}
                  >
                    {moment(Date.now()).tz("Asia/Dubai").format("hh:mm A")}
                  </Typography>
                </Box>
              </Box>
              <CircleIcon
                sx={{
                  color: "#4C4C4C",
                  fontSize: { lg: "1.3rem", md: "1.1rem", sm: "1rem" },
                }}
              />
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                  justifyContent: "center",
                  width: "28%",
                }}
              >
                <img src={flag2} alt="" srcset="" style={{ height: "35px" }} />
                <Box>
                  <Typography
                    sx={{
                      fontSize: { lg: "1.8rem", md: "1.6rem", sm: "1.5rem" },
                      color: "#A9A9A9",
                      letterSpacing: "3px",
                    }}
                  >
                    {moment(Date.now()).tz("Asia/Kolkata").format("hh:mm A")}
                  </Typography>
                </Box>
              </Box>
              <CircleIcon
                sx={{
                  color: "#4C4C4C",
                  fontSize: { lg: "1.3rem", md: "1.1rem", sm: "1rem" },
                }}
              />
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  mr: "20px",
                  gap: "10px",
                  width: "26%",
                  justifyContent: "end",
                }}
              >
                <img src={flag3} alt="" srcset="" style={{ height: "35px" }} />
                <Box>
                  <Typography
                    sx={{
                      fontSize: { lg: "1.8rem", md: "1.6rem", sm: "1.5rem" },
                      color: "#A9A9A9",
                      letterSpacing: "3px",
                    }}
                  >
                    {moment(Date.now())
                      .tz("America/New_York")
                      .format("hh:mm A")}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box className={classes.dateBox}>
            <Typography
              sx={{
                color: "#A9A9A9",
                fontSize: { lg: "1.9rem", md: "1.6rem", sm: "1.5rem" },
                fontWeight: "bold",
                letterSpacing: "4px",
              }}
            >
              {formatDate().toUpperCase()}
            </Typography>
          </Box>
          <Box className={classes.commoditieTable}>
            <Box className={classes.table}>
              <Box className={classes.tabeHeader}>
                <Typography
                  sx={{
                    flex: 1,
                    display: "flex",
                    justifyContent: "center",
                    fontSize: "2vw",
                    fontWeight: "bold",
                  }}
                  className={classes.fontgoldColor}
                >
                  COMMODITY
                </Typography>
                <Typography
                  sx={{
                    flex: 1,
                    display: "flex",
                    justifyContent: "center",
                    fontSize: "2vw",
                    fontWeight: "bold",
                  }}
                  className={classes.fontgoldColor}
                >
                  WEIGHT
                </Typography>
                <Typography
                  sx={{
                    flex: 1,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontSize: "2vw",
                    fontWeight: "bold",
                  }}
                  className={classes.fontgoldColor}
                >
                  BUY
                  <Typography sx={{ fontSize: "0.9vw", mt: 2.2 }}>
                    &nbsp;AED
                  </Typography>
                </Typography>
                <Typography
                  sx={{
                    flex: 1,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontSize: "2vw",
                    fontWeight: "bold",
                  }}
                  className={classes.fontgoldColor}
                >
                  SELL
                  <Typography sx={{ fontSize: "0.9vw", mt: 2.2 }}>
                    &nbsp;AED
                  </Typography>
                </Typography>
              </Box>

              <Box className={classes.tableContent}>
                {commodities?.map((commodity, idx) => {
                  const words = commodity.commodity_title.split(" ");
                  return (
                    <>
                      <Box
                        key={idx}
                        className={classes.tableRow1}
                        sx={{
                          maxHeight: "20%",
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: "1.3vw",
                            fontWeight: "bold",
                            justifyContent: "left",
                          }}
                          className={classes.tableRowColumn}
                        >
                          {words?.map((word, index) => {
                            if (index === 0) {
                              return (
                                <span
                                  key={index}
                                  style={{
                                    fontSize: "2vw",
                                    fontWeight: "bold",
                                    paddingLeft: "30px",
                                  }}
                                >
                                  {word.toUpperCase()}
                                </span>
                              );
                            } else {
                              return (
                                <span
                                  key={index}
                                  style={{
                                    fontSize: "1vw",
                                    marginTop: "0.2rem",
                                    fontWeight: "bold",
                                  }}
                                >
                                  &nbsp;{word.toUpperCase()}
                                </span>
                              );
                            }
                          })}
                          &nbsp;
                          {commodity.unitLabel === "TTB"
                            ? ""
                            : commodity.displayPurity}
                        </Typography>
                        <Box
                          className={classes.tableRowColumn}
                          sx={{
                            justifyContent: "left",
                          }}
                        >
                          <Typography
                            style={{ fontSize: "2vw", fontWeight: "bold" }}
                            sx={{
                              paddingLeft: { lg: "4.5vw", md: "3.5vw" },
                            }}
                          >
                            {commodity.unit} {commodity.unitLabel}
                          </Typography>
                        </Box>
                        <Box
                          className={classes.tableRowColumn}
                          sx={{
                            justifyContent: "left",
                          }}
                        >
                          <Typography
                            style={{
                              fontSize: "2vw",
                              fontWeight: "bold",
                            }}
                            sx={{
                              paddingLeft: { lg: "3.8vw", md: "3vw" },
                            }}
                          >
                            {commodityCalculation(
                              commodity.commodity_title === "Silver"
                                ? silver.bid
                                : gold.bid,
                              commodity.commodity_title === "Silver"
                                ? spread.silverBidSpread
                                : spread.goldBidSpread,
                              commodity.buy_premium,
                              3.674,
                              commodity.purity,
                              commodity.unit,
                              commodity.unitLabel === "TTB"
                                ? 116.64
                                : commodity.unitLabel === "KG"
                                ? 1000
                                : commodity.unitLabel === "OZ"
                                ? 31.1
                                : commodity.unitLabel === "TOLA"
                                ? 11.7
                                : 1,
                              commodity.buy_charge
                            )}
                          </Typography>
                        </Box>
                        <Box
                          className={classes.tableRowColumn}
                          sx={{
                            justifyContent: "left",
                          }}
                        >
                          <Typography
                            style={{ fontSize: "2vw", fontWeight: "bold" }}
                            sx={{
                              paddingLeft: { lg: "3.8vw", md: "3vw" },
                            }}
                          >
                            {commodityCalculation(
                              commodity.commodity_title === "Silver"
                                ? silver.ask
                                : gold.ask,
                              commodity.commodity_title === "Silver"
                                ? spread.silverAskSpread
                                : spread.goldAskSpread,
                              commodity.premium,
                              3.674,
                              commodity.purity,
                              commodity.unit,
                              commodity.unitLabel === "TTB"
                                ? 116.64
                                : commodity.unitLabel === "KG"
                                ? 1000
                                : commodity.unitLabel === "OZ"
                                ? 31.1
                                : commodity.unitLabel === "TOLA"
                                ? 11.7
                                : 1,
                              commodity.charges
                            )}
                          </Typography>
                        </Box>
                      </Box>
                      <Divider sx={{ bgcolor: "#4C4C4C" }} />
                    </>
                  );
                })}
              </Box>
            </Box>
          </Box>
          <Box className={classes.updates}>
            <Box className={classes.updatesHeader}>
              <Typography
                sx={{
                  fontSize: "1rem",
                  fontWeight: "bold",
                  textAlign: "center",
                  mt: "8px",
                  mb: "5px",
                  letterSpacing: "1.5px",
                }}
                className={classes.fontgoldColor}
              >
                MOON JEWELLERY
                <br />
                <Box
                  sx={{
                    fontSize: "1.4rem",
                    letterSpacing: "2px",
                    marginTop: -1,
                  }}
                >
                  UPDATES
                </Box>
              </Typography>
            </Box>
            <Box
              className={classes.updatesContent}
              sx={{ minHeight: "100%", width: "100%" }}
            >
              <Marquee
                speed={15}
                delay="2"
                style={{ height: "100%" }}
                gradient={false}
              >
                {news?.map((item, index) => {
                  return (
                    <div
                      key={item._id}
                      style={{
                        fontSize: "1.3vw",
                        fontFamily: "poppins",
                        alignItems: "center",
                        display: "flex",
                      }}
                    >
                      <span style={{ fontWeight: "bold" }}>
                        {item.newsTitle}
                      </span>
                      &nbsp;&nbsp;:&nbsp;&nbsp;<span>{item.newsDetails}</span>
                      <span
                        style={{ paddingRight: "50px", paddingLeft: "50px" }}
                      >
                        {index === news.length - 1 ? "" : "|"}
                      </span>
                    </div>
                  );
                })}
              </Marquee>
              <LogoutIcon
                sx={{ fontSize: "40px", color: "black", cursor: "pointer" }}
                onClick={handleLogout}
              />
            </Box>
          </Box>
        </Box>
        <Box className={classes.right}>
          <Box className={classes.banner}>
            <Carousel
              animation="fade" // Set the animation type to slide
              navButtonsAlwaysVisible={false} // Show navigation buttons always
              interval={10000}
              indicatorContainerProps={{
                style: {
                  display: "none",
                },
              }}
              sx={{
                height: "100%",
              }}
              transitionTime={3000} // Adjust the transition time to control the speed of the fade effect
              autoPlay
              duration={2000}
            >
              {banners?.map((banner, i) => (
                <img
                  style={{
                    width: "100%",
                    height: "23vh",
                    objectFit: "cover",
                  }}
                  src={banner.imageUrl}
                  alt="banner"
                />
              ))}
            </Carousel>
          </Box>
          <Box className={classes.spotRate}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                height: "15%",
                justifyContent: "center",
                display: "flex",
                backgroundColor: "#1A1A1A",
                border: "1px solid #4C4C4C",
                borderRadius: "10px",
              }}
            >
              <Typography
                sx={{
                  fontSize: "1.5VW",
                  fontWeight: "bold",
                }}
                className={classes.fontgoldColor}
              >
                SPOT RATE
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                height: "15%",
              }}
            >
              <Typography
                sx={{
                  fontSize: "1.4vw",
                  fontWeight: "bold",
                  flexBasis: 0,
                  flexGrow: 0.8,
                  flexShrink: 1,
                  display: "flex",
                  justifyContent: "center",
                }}
              ></Typography>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "5px",
                  flexBasis: 0,
                  flexGrow: 1,
                  flexShrink: 1,
                }}
              >
                <Typography sx={{ fontSize: "1.4vw", fontWeight: "bold" }}>
                  {displayBidOrBuy?.bidOrBuy?.toUpperCase()}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "5px",
                  flexBasis: 0,
                  flexGrow: 1,
                  flexShrink: 1,
                }}
              >
                <Typography sx={{ fontSize: "1.4vw", fontWeight: "bold" }}>
                  {displayBidOrBuy?.askOrSell?.toUpperCase()}
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                height: "35%",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{
                  fontSize: "1.8vw",
                  fontWeight: "bold",
                  p: 0,
                  flexBasis: 0,
                  flexGrow: 0.8,
                  flexShrink: 1,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                GOLD
                <br />
                <Typography
                  sx={{
                    fontSize: "1.8vw",
                    fontWeight: "bold",
                    flexBasis: 0,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: -1.5,
                  }}
                >
                  Oz
                </Typography>
              </Typography>

              <Box className={classes.spotRateBoxGoldRow2Col1}>
                <Box
                  sx={{
                    backgroundColor:
                      Number(gold?.bid).toFixed(2) <
                      Number(previousGold?.bid).toFixed(2)
                        ? "#EF2555"
                        : Number(gold?.bid).toFixed(2) >
                          Number(previousGold?.bid).toFixed(2)
                        ? "#10A88A"
                        : "",
                    border: "1px solid #FFFFFF",
                    color: "#FFFFFF",
                    borderRadius: "5px",
                    width: "7.3vw",
                    height: "7vh",
                    display: "flex",
                    alignItems: "center",
                    px: 1.5,
                    transition: "background-color .5s ease",
                  }}
                >
                  <Typography sx={{ fontSize: "2vw", fontWeight: "bold" }}>
                    {(Number(spread.goldBidSpread) + Number(gold.bid)).toFixed(
                      2
                    )}
                  </Typography>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    gap: "5px",
                    alignItems: "center",
                  }}
                >
                  <Typography style={{ fontSize: "1vw", color: "#EF2555" }}>
                    Low
                  </Typography>

                  <Typography style={{ fontSize: "1vw" }}>
                    {(
                      Number(spread.goldLowSpread) + Number(gold.lowPrice)
                    ).toFixed(2)}
                  </Typography>
                </Box>
              </Box>

              <Box className={classes.spotRateBoxGoldRow2Col2}>
                <Box
                  sx={{
                    backgroundColor:
                      Number(gold?.ask).toFixed(2) <
                      Number(previousGold?.ask).toFixed(2)
                        ? "#EF2555"
                        : Number(gold?.ask).toFixed(2) >
                          Number(previousGold?.ask).toFixed(2)
                        ? "#10A88A"
                        : "",
                    border: "1px solid #FFFFFF",
                    color: "#FFFFFF",
                    borderRadius: "5px",
                    width: "7.3vw",
                    height: "7vh",
                    display: "flex",
                    alignItems: "center",
                    px: 1.5,
                    transition: "background-color .5s ease",
                  }}
                >
                  <Typography sx={{ fontSize: "2vw", fontWeight: "bold" }}>
                    {(Number(spread.goldAskSpread) + Number(gold.ask)).toFixed(
                      2
                    )}
                  </Typography>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    gap: "5px",
                    alignItems: "center",
                  }}
                >
                  <Typography style={{ fontSize: "1vw", color: "#10A88A" }}>
                    High
                  </Typography>
                  <Typography style={{ fontSize: "1vw" }}>
                    {(
                      Number(spread.goldHighSpread) + Number(gold.highPrice)
                    ).toFixed(2)}
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Divider sx={{ bgcolor: "#4C4C4C" }} />
            <Box
              sx={{
                height: "35%",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{
                  fontSize: "1.1vw",
                  flexBasis: 0,
                  flexGrow: 0.8,
                  flexShrink: 1,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  mt: -3,
                }}
              >
                SILVER
                <Typography
                  sx={{
                    fontSize: "1.1vw",
                    flexBasis: 0,
                    display: "flex",
                    justifyContent: "center",
                    marginTop: -1,
                  }}
                >
                  Oz
                </Typography>
              </Typography>

              <Box className={classes.spotRateBoxSilverRow2Col1}>
                <Box
                  sx={{
                    backgroundColor:
                      Number(silver?.bid).toFixed(2) <
                      Number(previousSilver?.bid).toFixed(2)
                        ? "#EF2555"
                        : Number(silver?.bid).toFixed(2) >
                          Number(previousSilver?.bid).toFixed(2)
                        ? "#10A88A"
                        : "",
                    border: "1px solid #FFFFFF",
                    color: "#FFFFFF",
                    borderRadius: "5px",
                    px: 1.5,
                    width: "7.3vw",
                    height: "6vh",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    transition: "background-color .5s ease",
                  }}
                >
                  <Typography sx={{ fontSize: "1.8vw" }}>
                    &nbsp;&nbsp;
                    {(
                      Number(spread.silverBidSpread) + Number(silver.bid)
                    ).toFixed(2)}
                    &nbsp;&nbsp;
                  </Typography>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    gap: "5px",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Typography sx={{ fontSize: "1vw", mx: "auto" }}>
                    <span style={{ color: "#EF2555" }}>Low</span> &nbsp;
                    {(
                      Number(spread.silverLowSpread) + Number(silver.lowPrice)
                    ).toFixed(2)}
                  </Typography>
                </Box>
              </Box>
              <Box className={classes.spotRateBoxSilverRow2Col2}>
                <Box
                  sx={{
                    backgroundColor:
                      Number(silver?.ask).toFixed(2) <
                      Number(previousSilver?.ask).toFixed(2)
                        ? "#EF2555"
                        : Number(silver?.ask).toFixed(2) >
                          Number(previousSilver?.ask).toFixed(2)
                        ? "#10A88A"
                        : "",
                    border: "1px solid #FFFFFF",
                    color: "#FFFFFF",
                    borderRadius: "5px",
                    px: 1.5,
                    width: "7.3vw",
                    height: "6vh",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    transition: "background-color .5s ease",
                  }}
                >
                  <Typography sx={{ fontSize: "1.8vw" }}>
                    &nbsp;&nbsp;
                    {(
                      Number(spread.silverAskSpread) + Number(silver.ask)
                    ).toFixed(2)}
                    &nbsp;&nbsp;
                  </Typography>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    gap: "5px",
                    alignItems: "center",
                  }}
                >
                  <Typography style={{ fontSize: "1vw" }}>
                    <span style={{ color: "#10A88A" }}>High</span> &nbsp;
                    {(
                      Number(spread.silverHighSpread) + Number(silver.highPrice)
                    ).toFixed(2)}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>

          <Box
            sx={{
              height: "20%",
              display: "flex",
              alignItems: "end",
              width: "100%",
            }}
          >
            <Box className={classes.stat}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                  px: 5,
                  py: 1,
                  boxSizing: "border-box",
                }}
              >
                <Typography sx={{ fontSize: "1.3vw" }}>BUYERS</Typography>
                <Typography
                  sx={{
                    color: "#F8FBFF",
                    fontSize: "1.3vw",
                    color:
                      Number(ratio?.chgValue.replace("%", "")) >= 0
                        ? "#10A88A"
                        : "#EF2555",
                  }}
                >
                  {ratio?.chgValue}
                </Typography>
                <Typography sx={{ fontSize: "1.3vw" }}>SELLERS</Typography>
              </Box>
              <Box sx={{ width: "90%", px: 4 }}>
                <LinearProgress
                  variant="determinate"
                  value={progress}
                  style={progressStyle}
                  sx={{
                    "& .MuiLinearProgress-bar": barStyle,
                  }}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                  px: 7,
                  py: 1,
                  boxSizing: "border-box",
                }}
              >
                <Typography sx={{ fontSize: "1.3vw", color: "#10A88A" }}>
                  {ratio?.Buyers}
                </Typography>
                <Box sx={{ mt: 1 }}>
                  <a href="https://www.bullionstats.com/">
                    <img
                      className={classes.bullionStatsImg}
                      src={bullionStats}
                      alt="bullionStats"
                    />
                  </a>
                </Box>
                <Typography sx={{ fontSize: "1.3vw", color: "#EF2555" }}>
                  {100 - Number(ratio?.Buyers?.match(/\d+/)[0])}%
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openlimitExpierd}
        closeAfterTransition
      >
        <Fade in={openlimitExpierd}>
          <Box>
            <LimitExceededModal logout={handleLogout} />
          </Box>
        </Fade>
      </Modal>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openSubscription}
        onClose={handleClose}
        closeAfterTransition
      >
        <Fade in={openSubscription}>
          <Box>
            <SubscriptionExpiringSoon
              date={tv?.package.expire_date}
              handleClose={handleClose}
            />
          </Box>
        </Fade>
      </Modal>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openSubscriptionExpierd}
        onClose={handleCloseSubscriptionExpierd}
        closeAfterTransition
      >
        <Fade in={openSubscriptionExpierd}>
          <Box>
            <SubscriptionExpired handleClose={handleLogout} />
          </Box>
        </Fade>
      </Modal>
    </div>
  );
};

export default Home;
